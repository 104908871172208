import React from 'react';
import './App.scss';
import Landing from './components/Landing';
import NotFound from './components/NotFound';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import Resume from './components/Resume';

function App() {
    const basename = process.env.PUBLIC_URL || '';

    return (
        <React.StrictMode>
            <BrowserRouter basename={basename}>
                <Routes>
                    <Route path="/" element={<Landing />} />
                    <Route path="/resume" element={<Resume />} />
                    <Route path="/404" element={<NotFound />} />
                    <Route path="*" element={<Navigate replace to="/404" />} />
                </Routes>
            </BrowserRouter>
        </React.StrictMode>
    );
}

export default App;
